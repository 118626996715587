<template>
  <div class='indexbox'>
    <div class="tablebox">
      <div class="draggable" v-loading='loading'>
        <el-table :data="tableData" stripe>
          <el-table-column v-for="(item,index) in oldList" :key="`col_${index}`" :index='index' :prop="newList[index].prop" :label="item.label" :align="item.align" :min-width='item.width' :sortable='item.sortable' :fixed='item.fixed' show-overflow-tooltip>
          </el-table-column>
        </el-table>
      </div>
      <el-pagination @current-change='pagechage' background layout="total,prev, pager, next" v-show="total>0" :current-page.sync="page" :page-size="10" :total="total" />
    </div>
  </div>
</template>
<script>
  import Sortable from 'sortablejs';
  import { mapGetters } from 'vuex';
  export default {
    computed: {
      ...mapGetters(['api_id']),
    },
    data() {
      return {
        total: 0,
        page: 1,
        tableData: [],
        tableItems: [
          {
            label: 'USER',
            prop: 'email',
            width: '180',
            align: 'center',
          },
          {
            label: 'DATE',
            prop: 'create_time',
            width: '200',
            align: 'center',
            sortable: true,
          },
          {
            label: 'ACTIVITY',
            prop: 'remark',
            width: '220',
            align: 'left',
          },
        ],
        oldList: [],
        newList: [],
        loading: false,
      };
    },
    methods: {
      getlist() {
        this.loading = true
        this.$post({
          url: "/app/operate_log/logList",
          data: {
            page: this.page,
            api_id: this.$store.state.user.api_id
          },
          success: (res) => {
            this.page = res.data.current_page
            this.tableData = res.data.data
            this.total = res.data.total
            setTimeout(() => {
              this.loading = false
            }, 200);
          },
          tip: () => {
            this.loading = false
          },
        })
      },
      pagechage(page) {
        this.page = page
        this.tableData = []
        this.getlist()
      },
      // 列拖拽
      columnDrop() {
        const wrapperTr = document.querySelector('.draggable .el-table__header-wrapper tr');
        this.sortable = Sortable.create(wrapperTr, {
          animation: 180,
          delay: 0,
          onEnd: evt => {
            const oldItem = this.newList[evt.oldIndex];
            this.newList.splice(evt.oldIndex, 1);
            this.newList.splice(evt.newIndex, 0, oldItem);
          }
        });
      },
    },
    mounted() {
      this.oldList = JSON.parse(JSON.stringify(this.tableItems))
      this.newList = JSON.parse(JSON.stringify(this.tableItems))
      this.columnDrop()
    },
    created() {
      this.getlist()
    },
  }
</script>
<style lang='scss' scoped>
  .indexbox {
    width: 100%;
    height: 100%;
    .searchbox {
      width: 100%;
      padding: 10px 20px 0px;
      background: #fff;
      border-bottom: 1px solid #babdcd;
      display: flex;
      justify-content: space-between;
      align-items: center;
      /deep/.el-row {
        width: 100%;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        .right {
          display: flex;
          justify-content: flex-end;
          align-items: center;
        }
      }
      /deep/.el-form {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .el-form-item {
          margin: 0 20px 10px 0 !important;
        }
        .el-input__inner {
          background: #edeff8;
          input {
            background: none;
          }
        }
      }
      /deep/.el-button {
        background-color: #d6f3e3;
        border-radius: 10px;
        color: #3b3b3b;
        border-color: #d6f3e3;
        align-self: flex-end;
      }
    }
    .tablebox {
      width: 100%;
      padding: 20px;
    }
    .formbox {
      /deep/.el-form-item {
        margin-bottom: 30px !important;
        .el-form-item__content {
          width: 100%;
          .el-button {
            width: 100%;
            height: 48px;
            background: $color;
            border-radius: 5px;
            border-color: $color;
          }
        }
      }
    }
  }
</style>